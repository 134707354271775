let config; 
if (process.env.REACT_APP_STAGE === 'production') {
  config = require('./config.prod');
} else if (process.env.REACT_APP_STAGE === 'staging') {
  config = require('./config.stage');
} else {
  config = require('./config.dev');
}


export default {
  ...config
};
