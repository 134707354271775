import React, { Component } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { withStyles } from '@mui/styles';

import Logo from '../../assets/images/Logo.png';

const styles = ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100vh',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    padding: '20px'
  },
  image: {
    height: '36px'
  },
  title: {
    marginLeft: '15px',
    fontFamily: 'Montserrat',
    fontWeight: 100,
    fontSize: '16px',
    lineHeight: '20px',
    letterSpacing: '0.03em',
    color: '#263238',
  },
  spinnerWrapper: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  spinner: {
    color: '#676767'
  }
});

class Loading extends Component {
  render() {
    const { classes } = this.props;

    return <div className={classes.container}>
      <div className={classes.header}>
        <div>
          <img src={Logo} alt="" className={classes.image} />
        </div>
        <div className={classes.title}>
          CIRCLE LAB ACCOUNT
        </div>
      </div>

      <div className={classes.spinnerWrapper}>
        <CircularProgress className={classes.spinner} />
      </div>
    </div>
  }
}

export default withStyles(styles)(Loading);
