class GuardService {
  async isAuth() {
    try {
      const res = await fetch(`/isAuth`, {
        method: "GET",
        headers: { "Content-Type": "application/json", "Accept": "application/json", "Cache": "no-cache" },
        mode: "no-cors",
        credentials: 'include'
      });
      if (!res.ok) {
        throw new Error(`${res.status}: ${res.statusText}`);
      }
      const json = await res.json();
      if (!json.userProfile) {
        return false;
      }
      return json;
    }
    catch (err) {
      console.log(err.message);
    }
  }
}

const guardService = new GuardService();
export default guardService;
