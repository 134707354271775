import React, { Component } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Loadable from 'react-loadable';
import './App.css';
import guardService from './services/GuardService';
import Snackbar from '@mui/material/Snackbar';
import { ThemeProvider } from '@mui/material/styles';
import config from './config';

import { connect } from 'react-redux';

import Loading from './components/Loading/Loading';
import { createTheme } from '@mui/material';

const Container = Loadable({
  loader: () => import('./pages/Container/Container'),
  loading: Loading,
});

const theme = createTheme({
  typography: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  overrides: {
    MuiPaper: {
      rounded: {
        borderRadius: '10px'
      }
    }
  }
});


class App extends Component {

  state = {};


  async componentDidMount() {
    let auth = await guardService.isAuth();
    let userProfile = auth.userProfile;
    let communities = auth.communities;
    let organizations = auth.organizations;
    let isAdmin = auth.isAdmin;
    if (userProfile) {
      let isAuth = true;
      this.props.dispatch({
        type: 'IS_AUTH',
        isAuth
      });
      this.props.dispatch({
        type: 'IS_ADMIN',
        isAdmin
      });
      this.props.dispatch({
        type: 'PROFILE',
        userProfile
      });
      this.props.dispatch({
        type: 'ORGANIZATIONS',
        organizations
      });
      this.props.dispatch({
        type: 'COMMUNITIES',
        communities
      });
    }
    let isDataFetched = true;
    this.props.dispatch({
      type: 'IS_DATA_FETCHED',
      isDataFetched
    });

    const urlParams = new URLSearchParams(window.location.search);
    if (auth && urlParams.get('redirect') && (urlParams.get('email') && (userProfile.email || '').trim().toLowerCase() !== (urlParams.get('email') || '').trim().toLowerCase()) && !urlParams.get('redirected')) {
      let callback = encodeURIComponent(window.location.href);
      window.location = `${config.default.URL}/logout?returnUrl=${callback}`;
      return;
    } else if (auth && (urlParams.get('email') && (userProfile.email || '').trim().toLowerCase() !== (urlParams.get('email') || '').trim().toLowerCase())) {
      alert(`You logged in with a user ${userProfile.email}. Expected user ${urlParams.get('email')}`);
    }

    const message = urlParams.get('message');
    if (message) {
      const decodedMessage = Buffer.from(message, 'base64').toString('ascii');
      this.setState({ openSnackbar: true, snackbarMessage: decodedMessage });
    }

    if (!auth) {
      this.redirectToLoginPage();
    }
  }

  handleSnackbarClose = () => {
    this.setState({ openSnackbar: false })
  };

  redirectToLoginPage(redirect) {
    let callback = window.location.href;

    if (callback.indexOf("?") > 0 && callback.indexOf("redirect") > 0 && callback.indexOf("redirected") < 0) {
      callback += "&redirected=true"
    }

    window.location = `${config.default.URL}/login?returnUrl=${encodeURIComponent(callback)}`
  }
  

  render() {
    const { isDataFetched, isAuth } = this.props.data;




    return (
      <ThemeProvider theme={theme}>
        <div>
          <Router>
            {
              isDataFetched
                ? isAuth
                  ? <div className="App">
                    <Container />
                  </div>
                  : this.redirectToLoginPage()
                : <Loading />
            }
          </Router>
          <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            open={this.state.openSnackbar}
            autoHideDuration={8000}
            onClose={this.handleSnackbarClose}
            ContentProps={{
              'aria-describedby': 'message-id',
            }}
            message={<span id="message-id">{this.state.snackbarMessage}</span>}
          />
        </div>
      </ThemeProvider>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    data: state
  }
};

export default connect(mapStateToProps)(App);
