let initialState = require('./appState');

const postReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'IS_AUTH':
      state.isAuth = action.isAuth;
      return { ...state };
    case 'PROFILE':
      state.profile = action.userProfile;
      return { ...state };
    case 'IS_DATA_FETCHED':
      state.isDataFetched = action.isDataFetched;
      return { ...state };
    case 'UPDATE_USERNAME':
      state.profile.firstname = action.response.firstname;
      state.profile.lastname = action.response.lastname;
      return { ...state }
    case 'UPDATE_USER_PHOTO':
      state.profile.picture = action.photoPath;
      return { ...state }
    case 'ORGANIZATIONS':
      state.organizations = action.organizations;
      return { ...state }
    case 'COMMUNITIES':
      state.communities = action.communities;
      return { ...state }
    case 'ADD_NEW_COMMUNITY':
      state.communities.push(action.community);
      return { ...state }
    case 'ADD_NEW_ORGANIZATION':
      state.organizations.push(action.organization);
      return { ...state }
    case 'IS_ADMIN':
      state.isAdmin = action.isAdmin;
      return { ...state }
    default:
      return state;
  }
}
export default postReducer;
